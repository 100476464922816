import React from "react";
import backArrow from "../../images/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

export const GetInTouch = () => {
  const navigate = useNavigate();
  return (
    <div className="get-in-touch">
      <div className="get-in-touch-inner">
        <div className="get-in-touch-head">
          <div className="back-img">
            <img src={backArrow} alt="" />
          </div>
          <div className="back-btn" onClick={() => navigate("/login")}>Back</div>
        </div>
        <div className="get-in-touch-body">
          <div className="get-in-touch-card">
            <div className="card-head">Get In Touch</div>
            <div className="input-box">
              <div className="input-label">First Name</div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <div className="input-label1">Last Name</div>
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <div className="input-label1">Phone Number</div>
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Service</div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <div className="input-label1">Budget</div>
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Message</div>
              <div className="input-field1">
                <textarea type="text" />
              </div>
            </div>
            <div className="get-in-touch-submit-btn">Submit</div>
          </div>
        </div>
      </div>
    </div>
  );
};
