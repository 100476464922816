import React from "react";
import service1 from "../../images/service1.svg";
import service2 from "../../images/service2.svg";
import cross from "../../images/cross.svg";
import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";
import explore from "../../images/explore-mobile.svg";
import "./style.css";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

export const PrivacyPolicy = (props) => {
  const { showSideNavbar, setShowSideNavbar } = props;
  return (
    <>
      <Header setShowSideNavbar={setShowSideNavbar} />
      <div className="privacy-policy">
        {showSideNavbar && (
          <div className="side-nav-bar">
            <div className="side-nav-bar-inner">
              <div className="side-nav-bar-menu">
                <div className="side-nav-bar-head">
                  <div
                    className="side-nav-bar-close-btn"
                    onClick={() => setShowSideNavbar(false)}
                  >
                    <img src={cross} alt=" " />
                  </div>
                </div>
                <div className="side-nav-bar-item selected-side-nav">Home</div>
                <div className="side-nav-bar-item ">Services</div>
                <div className="side-nav-bar-item ">Pricing</div>
                <div className="side-nav-bar-item ">Coupons</div>
                <div className="side-nav-bar-item ">Contact</div>
              </div>
            </div>
          </div>
        )}
        <div className="elevate-img"></div>
        <div className="privacy-policy-heading">Privacy Policy</div>
        <div className="privacy-policy-content">
          Capricorncorp ("we," "our," or "us") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you visit our website
          [www.capricorncorp.com] (the "Site") and use our services. Please read
          this privacy policy carefully. If you do not agree with the terms of
          this privacy policy, please do not access the site. 1. Information We
          Collect We may collect information about you in a variety of ways. The
          information we may collect on the Site includes: Personal Data
          Personally identifiable information, such as your name, shipping
          address, email address, and telephone number, and demographic
          information such as your age, gender, hometown, and interests, that
          you voluntarily give to us when you register with the Site or when you
          choose to participate in various activities related to the Site, such
          as online chat and message boards. Derivative Data Information our
          servers automatically collect when you access the Site, such as your
          IP address, browser type, operating system, access times, and the
          pages you have viewed directly before and after accessing the Site.
          Financial Data Financial information, such as data related to your
          payment method (e.g., valid credit card number, card brand, expiration
          date) that we may collect when you purchase, order, return, exchange,
          or request information about our services from the Site. 2. How We Use
          Your Information We use the information we collect in the following
          ways: • To operate and maintain the Site. • To improve your experience
          on our Site. • To respond to comments and questions and provide
          customer service. • To process transactions and send you related
          information, including purchase confirmations and invoices. • To send
          administrative information, such as updates to our terms, conditions,
          and policies. • To protect our company, website, and users. • To
          comply with legal obligations and resolve disputes. 3. Sharing Your
          Information We may share your information in the following situations:
          With Service Providers We may share your information with third-party
          service providers who perform services for us or on our behalf, such
          as payment processing, data analysis, email delivery, and marketing
          services. Business Transfers We may share or transfer your information
          in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company. With Your Consent We may disclose your
          personal information for any other purpose with your consent. 4.
          Security of Your Information We use administrative, technical, and
          physical security measures to protect your personal information. While
          we have taken reasonable steps to secure the personal information you
          provide, please be aware that no security measures are perfect or
          impenetrable and no method of data transmission can be guaranteed
          against interception or other types of misuse. 5. Your Data Protection
          Rights Depending on your location, you may have the following rights
          regarding your personal information: • The right to access – You have
          the right to request copies of your personal data. • The right to
          rectification – You have the right to request that we correct any
          information you believe is inaccurate or complete information you
          believe is incomplete. • The right to erasure – You have the right to
          request that we erase your personal data, under certain conditions. •
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data,
        </div>
      </div>
      <Footer />
    </>
  );
};
