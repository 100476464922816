import React from "react";
import backArrow from "../../images/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  return (
    <div className="forgot">
      <div className="forgot-inner">
        <div className="forgot-head">
          <div className="back-img">
            <img src={backArrow} alt="" />
          </div>
          <div className="back-btn" onClick={() => navigate("/login")}>Back</div>
        </div>
        <div className="forgot-body">
          <div className="forgot-card">
            <div className="card-head">Forgot Password?</div>
            <div className="card-label">
              Enter the email address associated with your account.
            </div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-field">
                <input type="text" />
              </div>
            </div>
            <div
              className="forgot-submit-btn"
              onClick={() => navigate("/verify-otp")}
            >
              SEND OTP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
