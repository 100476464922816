import React from "react";
import backArrow from "../../images/back-arrow.svg";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  return (
    <div className="login">
      <div className="login-inner">
        <div className="login-head">
          <div className="back-img">
            <img src={backArrow} alt="" />
          </div>
          <div className="back-btn" onClick={() => navigate("/")}>Back</div>
        </div>
        <div className="login-body">
          <div className="login-card">
            <div className="card-head">Welcome Back</div>
            <div className="card-label">Login with Email</div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-field">
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Password</div>
              <div className="input-field">
                <input type="password" />
              </div>
            </div>
            <div
              className="forgot-pass"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot your password?
            </div>
            <div
              className="login-submit-btn"
              onClick={() => navigate("/get-in-touch")}
            >
              LOGIN
            </div>
            <div className="or-section">
              <div className="or-text">OR</div>
            </div>
            <div className="register-text">
              Don’t have account? <strong>Register Now</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
