import React, { useState } from "react";
import { Homepage } from "./pages/Homepage/Homepage";
import { Login } from "./pages/Login/Login";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { OTP } from "./pages/OTP/OTP";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import { GetInTouch } from "./pages/GetInTouch/GetInTouch";
import { TermsAndConditionns } from "./pages/TermsAndConditionns/TermsAndConditionns";

function App() {
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Homepage
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
            />
          }
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/verify-otp" element={<OTP />} />
        <Route
          exact
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
            />
          }
        />
        <Route
          exact
          path="/terms-conditions"
          element={
            <TermsAndConditionns
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
            />
          }
        />
        <Route exact path="/get-in-touch" element={<GetInTouch />} />
      </Routes>
    </div>
  );
}

export default App;
