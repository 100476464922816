import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import darkLogo from "../../images/logo-dark.svg";
import hamburger from "../../images/hamburger.svg";
import darkHamburger from "../../images/hamburger-dark.svg";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

export const Header = (props) => {
  const [headerClass, setHeaderClass] = useState("");
  const { setShowSideNavbar } = props;
  const navigate = useNavigate();

  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setHeaderClass("scroll-header");
    } else {
      setHeaderClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className={"header " + headerClass}>
      <div className="inner-header">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={headerClass == "" ? logo : darkLogo} alt="logo" />
        </div>
        <div className="header-nav">
          <div className="header-nav-bar">
            <div className="nav-bar-item selected">Home</div>
            <div className="nav-bar-item">Services</div>
            <div className="nav-bar-item">Pricing</div>
            <div className="nav-bar-item">Coupons</div>
            <div className="nav-bar-item">Contact</div>
          </div>
          <div className="header-btns">
            <Link to="/login">
              <div className="solid-btn">Login</div>
            </Link>
            <div className="solid-btn">Sign Up</div>
          </div>
          <div
            className="hamburger-icon"
            onClick={() => setShowSideNavbar(true)}
          >
            <img src={headerClass == "" ? hamburger : darkHamburger} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
