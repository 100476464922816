import React from "react";
import backArrow from "../../images/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

export const OTP = () => {
  const navigate = useNavigate();
  return (
    <div className="otp">
      <div className="otp-inner">
        <div className="otp-head">
          <div className="back-img">
            <img src={backArrow} alt="" />
          </div>
          <div
            className="back-btn"
            onClick={() => navigate("/forgot-password")}
          >
            Back
          </div>
        </div>
        <div className="otp-body">
          <div className="otp-card">
            <div className="card-head">OTP Verification</div>
            <div className="card-label">
              Enter the one time password sent to your email address.
            </div>
            <div className="input-box">
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <input type="text" />
              </div>
            </div>
            <div className="resend-timer">00:59</div>
            <div className="dont-get">
              Do not get OTP? <span className="text-link">Send OTP</span>
            </div>
            <div className="otp-submit-btn">Submit</div>
          </div>
        </div>
      </div>
    </div>
  );
};
