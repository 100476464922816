import React from "react";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import linkedIn from "../../images/linkedIn.svg";
import twitter from "../../images/twitter.svg";
import copyright from "../../images/copyright.svg";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="inner-footer">
        <div className="footer-up-container">
          <div className="footer-up">
            <div className="footer-section">
              <div className="footer-section-head">Products</div>
              <div className="footer-section-item">Cloud Computer</div>
              <div className="footer-section-item">Cloud Storage</div>
              <div className="footer-section-item">Security & Compliance</div>
              <div className="footer-section-item">Cloud Networking</div>
              <div className="footer-section-item">Cloud Migration</div>
            </div>
            <div className="footer-section">
              <div className="footer-section-head">Features</div>
              <div className="footer-section-item">Data Center Locations</div>
              <div className="footer-section-item">Operating Systems</div>
              <div className="footer-section-item">Advanced Network</div>
              <div className="footer-section-item">Helpdesk</div>
              <div className="footer-section-item">Disaster Recovery</div>
            </div>
            <div className="footer-section-company">
              <div className="footer-section-head">Company</div>
              <div className="footer-section-item">Our Team</div>
              <div className="footer-section-item">News</div>
              <div className="footer-section-item">Reviews</div>
              <div className="footer-section-item">Coupons</div>
              <div className="footer-section-item">Contact</div>
            </div>
            <div className="footer-social-media">
              <img src={facebook} alt="" />
              <img src={instagram} alt="" />
              <img src={linkedIn} alt="" />
              <img src={twitter} alt="" />
            </div>
          </div>
        </div>
        <div className="mob-footer-section-company">
          <div className="footer-section-head">Company</div>
          <div className="footer-section-item">Our Team</div>
          <div className="footer-section-item">News</div>
          <div className="footer-section-item">Reviews</div>
          <div className="footer-section-item">Coupons</div>
          <div className="footer-section-item">Contact</div>
        </div>
        <div className="footer-down">
          <div className="footer-copyright-text">
            <img src={copyright} alt="" />
          </div>
          <div className="footer-nav-bar">
            <div className="footer-nav-item" onClick={() => navigate("/terms-conditions")}>
              <span>Terms & Conditions</span>{" "}
              <span className="mob-divider">&nbsp;|&nbsp;</span>
            </div>
            <div className="footer-nav-item" onClick={() => navigate("/privacy-policy")}>Privacy Policy</div>
          </div>
          <div className="mob-footer-social-media">
            <img src={facebook} alt="" />
            <img src={instagram} alt="" />
            <img src={linkedIn} alt="" />
            <img src={twitter} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
